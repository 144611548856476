// src/Home.js
import React, { useEffect, useState } from 'react';
import Menu from '../menu';
import { getTasks, createTask, updateTask } from './taskApi';
import { useSelector, useDispatch } from 'react-redux';

import DateTimePicker from 'react-datetime-picker'
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';

import { selectName, selectUserId, selectUsers, setIsLogOut, setName, setUserId } from '../login/loginSlice';
import './task.css'
import { addIcon, closeIcon } from '../../static/svgs';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

function Tasks({ setIsLoggedIn }) {
    const dispatch = useDispatch()
    const [tasks, setTasks] = useState([]);
    const [isModalVisible, setModalVisible] = useState(false);
    const [newTaskText, setNewTaskText] = useState('');
    const user_id = useSelector(selectUserId)
    const username = useSelector(selectName)
    const users = useSelector(selectUsers)

    const [date, setDate] = useState(new Date(1598051730000));
    const [time, setTime] = useState('10:00');

    const [mode, setMode] = useState('date');
    const [show, setShow] = useState(false);

    const [isChecked, setChecked] = useState(false);

    const [selectedTask, setSelectedTask] = useState(null);


    const onChange = (event, selectedDate) => {
        const currentDate = selectedDate;
        setShow(false);
        setDate(currentDate);
    };

    const showMode = (currentMode) => {
        setShow(true);
        setMode(currentMode);
    };

    const showDatepicker = () => {
        showMode('date');
    };

    const showTimepicker = () => {
        showMode('time');
    };

    const toggleModal = () => {
        setNewTaskText('')
        setSelectedTask(null)
        setModalVisible(!isModalVisible);
    };

    const handleTaskPress = (selectedTask) => {
        setSelectedTask(selectedTask); // Устанавливаем выбранную задачу в состояние

        setNewTaskText(selectedTask.text); // Устанавливаем текст задачи
        setDate(new Date(selectedTask.timeout)); // Устанавливаем таймаут задачи
        setChecked(selectedTask.urgency === 1); // Устанавливаем срочность задачи


        setModalVisible(true); // Открываем модальное окно
    };


    const handleCreateTask = () => {
        createTask(user_id, newTaskText, {
            timeout: date,
            urgency: isChecked ? 1 : 0
        }, data => {
            console.log('createTask', data)
            setTasks([...tasks, data]);
            setNewTaskText('');
            toggleModal();
        });
    };

    const handleUpdateTask = () => {
        if (!selectedTask) {
            return; // Если задача не выбрана, ничего не делаем
        }

        updateTask(selectedTask.id, newTaskText, {
            timeout: date,
            urgency: isChecked ? 1 : 0
        }, data => {
            console.log('updateTask', data);
            // Обновляем состояние задач в списке
            setTasks(tasks.map(task => task.id === selectedTask.id ? data : task));
            setNewTaskText('');
            toggleModal();
        });
    };

    const handleLogOut = () => {
        setIsLoggedIn(false)
        dispatch(setIsLogOut(true))
    }

    const handleClickUser = (user) => {
        console.log('handleClickUser', user)
        getTasks(user.user_id)
            .then(data => {
                // console.log(data)
                setTasks(data);
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
        dispatch(setName(user.company_name))
        dispatch(setUserId(user.user_id))
    }

    useEffect(() => {
        getTasks(user_id)
            .then(data => {
                console.log(data)
                setTasks(data);
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
    }, [user_id]);
    return (
        <div>
            <Menu handleClickUser={handleClickUser}/>
            {/* <h2>Задачи</h2> */}


            <div>
                {/* Список задач */}
                <div className='cntr_tasks'>
                    {tasks.map((item) => (
                        <div key={item.id} onClick={() => handleTaskPress(item)}>
                            <div className="task">
                                <label className="taskDateTime">{item.datetime.slice(0, 10)} {item.datetime.slice(11, 16)}</label>
                                <br />
                                <label className="taskText">{item.text}</label>
                                <div className="taskDetails">
                                    <div>
                                        <label className="taskDetail">Таймаут:</label>
                                        <label className="taskDetail">{item.timeout.slice(0, 10)} {item.timeout.slice(11, 16)}</label>
                                    </div>
                                    {item.urgency === 1 && !item.finish_date && (
                                        <label className="taskDetail" style={{ color: 'red' }}>Срочно</label>
                                    )}
                                    {item.finish_date && (
                                        <label className="taskDetail">Исполнено {item.finish_date.slice(0, 10)}</label>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='add_icon' onClick={toggleModal}>
                    {addIcon}
                </div>
                {/* Модальное окно для создания/редактирования задачи */}
                {isModalVisible && (
                    <div className="modal-container">
                        <div className="modal-content">
                            <div className="close-button" onClick={toggleModal}>
                                {closeIcon}
                            </div>
                            <h5 className="modal-title">Введите текст задачи:</h5>
                            <input
                                className="input_task"
                                type="text"
                                placeholder="Текст задачи"
                                value={newTaskText}
                                onChange={(e) => setNewTaskText(e.target.value)}
                            />
                            <div className="date-details">
                                <span>Таймаут:</span>
                                {/* <button className="date-button" onClick={showDatepicker}>
                                    {date?.toLocaleString().slice(0, 10)}
                                </button> */}
                                {/* <button className="date-button" onClick={showTimepicker}>
                                    {date?.toLocaleString().slice(12, 17)}
                                </button> */}
                                {/* <input aria-label="Time" type="time" /> */}
                                <TimePicker onChange={setTime} value={time} className='datepicker' />
                                {true && (
                                    <DatePicker onChange={setDate} value={date} className='datepicker' />
                                )}
                            </div>
                            <div className="checkbox-container">
                                <span>Срочно</span>
                                <input
                                    type='checkbox'
                                    checked={isChecked}
                                    onChange={(e) => setChecked(e.target.checked)}
                                    color="primary"
                                />
                            </div>
                            <div style={{textAlign: 'right'}}>
                                {selectedTask ? (
                                    <button className="create-button" onClick={handleUpdateTask}>
                                        Сохранить
                                    </button>
                                ) : (
                                    <button className="create-button" onClick={handleCreateTask}>
                                        Создать
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>


        </div>
    );
}

export default Tasks;
