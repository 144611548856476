import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Menu from './components/menu';
import Home from './components/home/home';
import Tasks from './components/tasks/tasks';
import Chat from './components/chat/chat';
import Contacts from './components/contacts/contacts';
import './App.css';
import Login from './components/login/login';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/tasks" element={<Tasks/>} />
        <Route path="/chat" element={<Chat/>} />
        <Route path="/contacts" element={<Contacts/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
