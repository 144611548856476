import  client  from "socket.io-client";
// import { url } from "./constants";
// const url = process.env.REACT_APP_API_URL;
import { url } from "../../static/env";

const socket = client(url);

export function useSocket () {
    
    return {
        socket
    }
}

