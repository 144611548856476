import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from './loginApi';
import { selectIsLogOut, selectUsers, setName, setUserId, setUsers } from './loginSlice';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const users = useSelector(selectUsers);
    const isLogout = useSelector(selectIsLogOut);

    const handleLogin = () => {
        loginUser(username, password)
            .then(data => {
                if (data.error) {
                    alert('Ошибка', 'Неверные данные');
                } else {
                    dispatch(setName(data.name));
                    dispatch(setUserId(data.id));
                    // onLogin(data);
                    const newUser = { username, password, company_name: data.name, user_id: data.id };
                    const existingUserIndex = users.findIndex(user => user.username === newUser.username);
                    if (existingUserIndex === -1) {
                        const updatedUsers = [...users, newUser];
                        localStorage.setItem('userList', JSON.stringify(updatedUsers));
                        dispatch(setUsers(updatedUsers));
                    } else {
                        const updatedUsers = [...users];
                        updatedUsers[existingUserIndex] = newUser;
                        localStorage.setItem('userList', JSON.stringify(updatedUsers));
                        dispatch(setUsers(updatedUsers));
                    }
                    navigate('/home')
                }
            })
            .catch(error => {
                console.error('Ошибка при входе:', error);
            });
    };

    useEffect(() => {
        const loadUserData = async () => {
            try {
                const userData = localStorage.getItem('userList');
                if (userData) {
                    const parsedUserData = JSON.parse(userData);
                    dispatch(setUsers(parsedUserData));
                    if (parsedUserData.length > 0) {
                        const lastUser = parsedUserData[parsedUserData.length - 1];
                        loginUser(lastUser.username, lastUser.password)
                            .then(data => {
                                if (!data.error) {
                                    const newUser = { username: lastUser.username, password: lastUser.password, company_name: data.name, user_id: data.id };
                                    const updatedUsers = [...parsedUserData];
                                    updatedUsers[parsedUserData.length - 1] = newUser;
                                    localStorage.setItem('userList', JSON.stringify(updatedUsers));
                                    console.log('localstorage', data)
                                    dispatch(setUsers(updatedUsers));
                                    dispatch(setName(data.name));
                                    dispatch(setUserId(data.id));
                                    navigate('/home')
                                    // onLogin(data);
                                }
                            })
                            .catch(error => {
                                console.error('Ошибка при автоматической авторизации:', error);
                            });
                    }
                }
            } catch (error) {
                console.error('Failed to load user data:', error);
            }
        };

        if (!isLogout) loadUserData();
    }, []);

    return (
        <div style={{ width: '100%', display: 'flex', gap: '30px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <h1>Вход</h1>
            <input
                style={{ height: '40px', width: '50%', backgroundColor: 'white', borderRadius: '5px', boxSizing: 'border-box', padding: '0 10px', border: '1px solid rgba(228, 228, 228, 1)', color: '#777' }}
                type="text"
                placeholder="Логин"
                value={username}
                onChange={e => setUsername(e.target.value)}
            />
            <input
                style={{ height: '40px', width: '50%', backgroundColor: 'white', borderRadius: '5px', padding: '0 10px', boxSizing: 'border-box', border: '1px solid rgba(228, 228, 228, 1)', color: '#777' }}
                type="password"
                placeholder="Пароль"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <button
                style={{ 
                    height: '40px', 
                    width: '50%', 
                    backgroundColor: '#777', 
                    borderRadius: '5px', 
                    color: '#fff', 
                    fontSize: '16px', 
                    boxSizing: 'border-box' ,
                    border: 'none',
                    cursor: 'pointer'
                }}
                onClick={handleLogin}
            >
                Войти
            </button>
        </div>
    );
};

export default Login;
