import React from 'react';
import Menu from '../menu';

const Contacts = () => {
  return (
    <div>
      <Menu/>
    <div style={styles.container}>
      
      <h2 style={styles.title}>Регистратор</h2>
      <div>
        <p style={styles.heading}>Адрес офиса:</p>
        <p style={styles.text}>г. Санкт-Петербург, ул. Новгородская, 23</p>

        <p style={styles.heading}>Единый контактный телефон:</p>
        <p style={styles.text}>8 (812) 331-46-23</p>
        {/* <p style={styles.text}>8 (800) 101-74-34</p> */}

        <p style={styles.heading}>Режим работы офиса:</p>
        <p style={styles.text}>с 9-00 до 18-00</p>
        <p style={styles.text}>без перерывов</p>

        <p style={styles.heading}>Электронная почта:</p>
        <p style={styles.text}>buch2@registratorspb.ru</p>
      </div>
    </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',
    margin: '10px',
  },
  title: {
    fontFamily: 'Lobster-Regular',
    color: 'rgba(0, 114, 188, 0.8)',
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '30px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#333',
  },
  text: {
    fontSize: '16px',
    marginBottom: '3px',
    color: '#666',
  },
};

export default Contacts;
