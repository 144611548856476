import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectName, selectUserId, selectUsers, setIsLogOut, setName } from './login/loginSlice';
import { useNavigate } from 'react-router-dom';
import '../static/css/main.css'

const SelecterUser = ({ handleClickUser }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const username = useSelector(selectName);
    const users = useSelector(selectUsers);
    const userId = useSelector(selectUserId)

    const handleLogOut = () => {
        dispatch(setIsLogOut(true))
        navigate('/')
    }

    return (
        <div style={styles.container} className='cntr_username'>
            <button className='btn_username' onClick={() => dispatch(setName(''))}>
                <span style={styles.username}>{username}</span>
            </button>
            {username === '' && (
                <div style={styles.listUsers}>
                    {users.map((user, index) => (
                        <button
                            style={styles.userItem}
                            key={index}
                            onClick={() => handleClickUser(user)}
                        >
                            <span style={styles.userText}>{user.company_name}</span>
                        </button>
                    ))}
                    <button
                        style={styles.logoutButton}
                        onClick={handleLogOut}
                    >
                        <span style={styles.logoutText}>Выйти</span>
                    </button>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        position: 'relative',
        zIndex: 25,
    },
    username: {
        color: '#777',
        fontSize: 10,
        textAlign: 'right',
    },
    listUsers: {
        backgroundColor: '#F0ECE5',
        padding: 5,
        borderRadius: 5,
        zIndex: 25,
        marginTop: 5,
        position: 'absolute',
        top: 10,
        left: 0,
        width: '150px'
    },
    userItem: {
        borderBottom: '1px solid #000',
        padding: '2px',
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        width: '100%',
        textAlign: 'left'
    },
    userText: {
        color: 'black',
        fontSize: 14,
        textAlign: 'right',
    },
    logoutButton: {
        backgroundColor: 'grey',
        borderRadius: 5,
        marginTop: 3,
        padding: '2px',
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        zIndex: 15,
    },
    logoutText: {
        textAlign: 'center',
    },
};

export default SelecterUser;
