import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userName: '',
    // user_id: 1,
    start_index: 0
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setUserName: (state, action) => {
            state.userName = action.payload;
        },
        // setUserId: (state, action) => {
        //     state.user_id = action.payload;
        // },
        setStartIndex: (state, action) => {
            state.start_index = action.payload;
        },
    },
});

export const { setUserName, setStartIndex } = chatSlice.actions;

export const selectUserName = (state) => state.chat.userName;
// export const selectUserId = (state) => state.chat.user_id;
export const selectStartIndex = (state) => state.chat.start_index;

export default chatSlice.reducer;
