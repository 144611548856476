import React from 'react';
import { Link } from 'react-router-dom';
import '../static/css/main.css';
import SelecterUser from './selecterUser';

function Menu( {handleClickUser} ) {
  const menuStyles = {
    listStyleType: 'none',
    gap: '10px',
  };

  return (
    <div>
      <ul style={menuStyles} className='d-f'>
        <li><Link to="/home" className="menu-link">Главная</Link></li>
        <li><Link to="/tasks" className="menu-link">Задачи</Link></li>
        <li><Link to="/chat" className="menu-link">Чат</Link></li>
        <li><Link to="/contacts" className="menu-link">Контакты</Link></li>
        <li>
          <SelecterUser handleClickUser={handleClickUser}/>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
