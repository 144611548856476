import React, { useState, useRef } from 'react';

const VoiceRecorder = (props) => {
    const [isRecording, setIsRecording] = useState(false);
    const setUploadFile = props.setUploadFile

    let mediaRecorder;
    const mediaRecorderRef = useRef(null);
    const chunks = [];

    const startRecording = async () => {
        console.log('startRecording')
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = handleDataAvailable;
            mediaRecorderRef.current.onstop = event => {
                console.log('stop')
                const blob = new Blob(chunks, { type: 'audio/wav' });
                let file = new File([blob], "name.wav");
                console.log('file', file)
                setUploadFile(file);
                setIsRecording(false)
            }
            mediaRecorderRef.current.onstart = event => {
                console.log("Recording started.");

                // startButton.disabled = true;
                // Stop recording when the time is up.
                // setTimeout(function() { recorder.stop(); }, 3000);
            };
            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const stopRecording = () => {
        console.log('stopRecording', mediaRecorder.state)
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
        }
        setIsRecording(false);
    };

    const handleDataAvailable = (event) => {
        chunks.push(event.data);
    };

    const handleStopRecording = () => {
        console.log('handleStopRecording')
        const blob = new Blob(chunks, { type: 'audio/wav' });
        let file = new File([blob], "name.wav");
        console.log('file', file)
        setUploadFile(file);
    };

    return (
        <div>
            {isRecording ? (
                <svg style={{width: 20}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                    onClick={() => {
                        // stopRecording();
                        // handleStopRecording();
                        mediaRecorderRef.current.stop()
                    }}>

                    <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm192-96H320c17.7 0 32 14.3 32 32V320c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32z" />
                </svg>
            ) : (
                <svg style={{width: 20}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                    onClick={startRecording}
                >
                    <path d="M192 0C139 0 96 43 96 96V256c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V430.4c85.8-11.7 152-85.3 152-174.4V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V216z" />
                </svg>
            )}
        </div>
    );
};

export default VoiceRecorder;
