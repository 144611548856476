import { url } from "../../static/env";
// const url = process.env.REACT_APP_API_URL;

export function getChats(pars, callback) {
    fetch(url + '/getchats', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pars)
    })
        .then((response) => response.json())
        .then((data) => {
            return callback(data);
        });
}

export function getMessages(user_id) {
    console.log('getMessages', user_id)
    return fetch(url + '/messages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        return data.messages;
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
        throw error;
      });
  }

export async function getMessages20(pars) {
    try {
        const response = await fetch(url + '/getmessages20', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pars)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching messages:', error);
        throw error;
    }
}

export function getAllMessages(callback) {
    fetch(url + '/getallmessages', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({})
    })
        .then((response) => response.json())
        .then((data) => {
            return callback(data);
        });
}

export function sendMessage(pars, callback) {
    fetch(url + '/sendmessage', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pars)
    })
        .then((response) => response.json())
        .then((data) => {
            return callback(data);
        });
}

export function sendMessageWA(pars, callback) {
    fetch(url + '/sendmessagewa', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pars)
    })
        .then((response) => response.json())
        .then((data) => {
            return callback(data);
        });
}

export function sendMessageFile(pars, callback) {
    let formData = new FormData();
    formData.append('message_file', pars.upload_file);
    formData.append('chat_id', pars.chat_id);
    formData.append('user_id', pars.user_id);

    fetch(url + '/sendmessagefile', {
        method: 'POST',
        body: formData
    })
        .then((response) => response.json())
        .then((data) => {
            return callback(data);
        });
}

export function sendMessageTest(pars) {
    fetch(url + '/sendmessagetest', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pars)
    })
        .then((response) => response.json())
        .then((data) => {
            // No callback provided
        });
}

export function sendLogin(pars, callback) {
    fetch(url + '/login', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pars)
    })
        .then((response) => response.json())
        .then((data) => {
            return callback(data);
        });
}


//new
export function addFileMessage(pars, callback) {
    let formData = new FormData();
  
    formData.append("file", pars.file);
  
    formData.append('user_id', pars.userId);
    formData.append('to_id', pars.toId);
    formData.append('text', pars.text);
  
    fetch(url + '/upload', {
      method: 'POST',
    //   mode: 'no-cors',
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        return callback(data);
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  }
