// src/Home.js
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css'
import { addFileMessage, getAllMessages, getMessages, getMessages20, sendMessage, sendMessageFile, sendMessageTest, sendMessageWA } from './chatApi';
import { useSocket } from './useSocket';
import { selectStartIndex, selectUserName, setStartIndex } from './chatSlice';
import { setUserId, selectUserId } from '../login/loginSlice';
import { useNavigate } from 'react-router-dom';
// import { url } from './constants';

import React from 'react';
import Menu from '../menu';

// const url = process.env.REACT_APP_API_URL;
import { url } from "../../static/env";
import VoiceRecorder from './VoiceRecorder';
import { setName } from '../login/loginSlice';

let current_chat = 1;
let state_alarm = false;
let alarmInterval;
let all_messages;
let chat_messages = {};
let chats_;

var tick = -1
let start_ind = 0

function Chat() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { socket } = useSocket();

    const userName = useSelector(selectUserName);
    const userId = useSelector(selectUserId);
    const start_index = useSelector(selectStartIndex);

    const [chats, setChats] = useState([]);
    const [message, setMessage] = useState('');
    const [uploadFile, setUploadFile] = useState(null);
    const [currentChatId, setCurrentChatId] = useState(1);
    const [chatMessages, setChatMessages] = useState([]);
    const [newMessageChatId, setNewMessageChatId] = useState(0);
    const [findText, setFindText] = useState('');
    const [findChatText, setFindChatText] = useState('');
    const [serverOn, setServerOn] = useState(true);
    const [qoutedMessageIndex, setQoutedMessageIndex] = useState(0);
    const [findChatsMsg, setfindChatsMsg] = useState([]);
    const [startIndexMsg, setStartIndexMsg] = useState(0);
    const [endIndexMsg, setEndIndexMsg] = useState(20);
    const [showLoad, setShowLoad] = useState(false);

    let end_ind = 20;
    let stop_scroll = false;

    function setOldScroll(diff) {
        var objDiv = document.getElementById("messages");

        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight - diff;
        }
    }

    function scrollToBottom() {
        clearInterval(alarmInterval);
        alarmInterval = 0;
        setDefaultIcon();

        var objDiv = document.getElementById("messages");
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;


    }

    function goUpChat(chat_id) {
        let arr_cht = chats.slice();
        const ind = arr_cht.findIndex(e => e.id === chat_id);
        let el_up = arr_cht[ind];
        for (let i = ind; i > 0; i--) {
            arr_cht[i] = arr_cht[i - 1];
        }
        arr_cht[0] = el_up;
        setChats(arr_cht);
        chats_ = arr_cht;
    }

    function goUpChatS(chat_id) {
        // let arr_cht = chats_.slice();
        // const ind = arr_cht.findIndex(e => e.id === chat_id);
        // let el_up = arr_cht[ind];
        // for (let i = ind; i > 0; i--) {
        //     arr_cht[i] = arr_cht[i - 1];
        // }
        // arr_cht[0] = el_up;
        // setChats(arr_cht);
        // chats_ = arr_cht;
    }

    function handleClickSendMessage() {
        if (uploadFile) {
            console.log('uploadFile', uploadFile)
            // goUpChat(currentChatId);
            // sendMessageFile({ upload_file: uploadFile, chat_id: currentChatId, user_id: userId }, (data) => {
            //     setUploadFile(false);
            //     let file = document.getElementById("myfile");
            //     if (file) file.value = '';
            //     console.log('handleClickSendMessage')
            //     getMessages({ chat_id: currentChatId, user_id: userId }, (data) => {
            //         setIndexes(data.messages, tick);
            //         setChatMessages(data.messages);
            //         setTimeout(() => {
            //             var objDiv = document.getElementById("messages");
            //             if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
            //         }, 1000);
            //     });
            // });

            addFileMessage(
                {
                    file: uploadFile,
                    type: uploadFile.mimeType,
                    userId: 1,
                    toId: 2,
                    text: message
                }, (data) => {
                    setUploadFile(false);
                    console.log('data file', data)
                })


        } else if (message.trim() === '') {
            return;
        } else {
            console.log('handleClickSendMessage')
            // goUpChat(currentChatId);
            // setMessage('');
            let inp = document.getElementById('inputmessage');
            if (inp) inp.style.height = '5vh';

            const quoted_msg_id = qoutedMessageIndex === 0 ? '' : chatMessages[qoutedMessageIndex].message_id;
            const serialized_id = qoutedMessageIndex === 0 ? '' : chatMessages[qoutedMessageIndex].serialized_id;

            // sendMessage({ text: message, chat_id: currentChatId, quoted_msg_id: quoted_msg_id, user_id: userId }, (data) => {
            //     setQoutedMessageIndex(0);
            //     const insert_id = data.id;
            //     getMessages({ chat_id: current_chat, user_id: userId }, (data) => {
            //         setIndexes(data.messages, tick);
            //         setChatMessages(data.messages);
            //         sendMessageWA({ text: `*${userName}*\n${message}`, chat_id: currentChatId, id: insert_id, serialized_id: serialized_id }, (data) => {
            //             if (data.res) setServerOn(true);
            //             else {
            //                 setServerOn(false);
            //                 alert('Сообщение: ' + message + ' не прошло. Сервер отключен');
            //             }
            //         });
            //         setTimeout(() => {
            //             var objDiv = document.getElementById("messages");
            //             if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
            //         }, 1000);
            //     });
            // });

            socket.emit('sendMessage', { text: message, from_id: userId, to_id: 2 });
            setMessage('');
            console.log('sendmesg')

        }
    }

    function setDefaultIcon() {
        const favicon = document.getElementById("icon");
        if (favicon) favicon.setAttribute("href", "favicon.ico");
    }

    function alarmNewMsg() {
        const favicon = document.getElementById("icon");
        if (state_alarm) {
            if (favicon) favicon.setAttribute("href", "newmsg.ico");
        } else {
            if (favicon) favicon.setAttribute("href", "favicon.ico");
        }
        state_alarm = !state_alarm;
    }

    const handleSocket = (arg) => {
        console.log('handleSocket', arg, userId)


        if (userId === parseInt(arg.from_id) || userId === parseInt(arg.to_id)) {
            // getMessages({ chat_id: arg.chat_id, user_id: userId }, (data) => {
            //     setIndexes(data.messages, tick);
            //     setChatMessages(data.messages);
            // });
            console.log('setChatMessages')
            setChatMessages((prevMessages) => [...prevMessages, arg]);
        }

        const favicon = document.getElementById("icon");
        if (favicon) {
            favicon.setAttribute("href", "newmsg.ico");
        }
        clearInterval(alarmInterval);
        alarmInterval = 0;
        if (alarmInterval === 0) {
            const interval = setInterval(() => alarmNewMsg(), 1000);
            alarmInterval = interval;
        }
    };

    function handleDoubleClick(index) {
        setQoutedMessageIndex(index);
    }

    function setIndexes(messages, tick) {
        console.log('setIndexes', messages.length);
        if (messages.length >= 20) {
            setStartIndexMsg(messages.length - 21);
            setEndIndexMsg(messages.length - 1);
        }
    }

    function createHandleScroll(chat_id, tick, stop_scroll) {
        return function handleScroll() {

            if (stop_scroll) return;
            else {
                // console.log('handleScroll', tick, stop_scroll);
                stop_scroll = true;

            }
            var objDiv = document.getElementById("messages");

            if (objDiv) {
                if (objDiv.scrollTop > objDiv.scrollHeight - 600 && !stop_scroll) {
                    stop_scroll = true;
                    clearInterval(alarmInterval);
                    alarmInterval = 0;
                    setDefaultIcon();


                    setTimeout(() => stop_scroll = false, 500);
                }
                else if (objDiv.scrollTop < 10 && chat_id) {
                    setTimeout(() => stop_scroll = false, 500);
                    let diff_h = objDiv.scrollHeight - objDiv.scrollTop;
                    stop_scroll = true;
                    if (tick > 20) {
                        setStartIndexMsg(tick - 20);
                        tick = tick - 20;
                        setTimeout(() => setOldScroll(diff_h), 100);
                        console.log('> 20', tick, diff_h);
                    }
                    else if (tick === -1 && chat_messages[chat_id]) {
                        // setChatMessages(chat_messages[chat_id]);
                        setStartIndexMsg(chat_messages[chat_id].length - 21);
                        setEndIndexMsg(chat_messages[chat_id].length - 1);
                        tick = chat_messages[chat_id].length - 21;
                        console.log('0-20');
                    }
                    else {
                        console.log('0');
                        start_ind = 0;
                        setStartIndexMsg(0);
                    }
                }
                else setTimeout(() => stop_scroll = false, 50);
            }

        };
    }

    let handleScroll;

    let timer = 0;

    function setHandleScroll(chat_id, tick) {
        // console.log('setHandleScroll', chat_id);
        const objDiv = document.getElementById('messages');

        if (objDiv) objDiv.removeEventListener('scroll', handleScroll);
        handleScroll = createHandleScroll(chat_id, tick, stop_scroll);
        if (objDiv) objDiv.addEventListener('scroll', handleScroll);
    }




    useEffect(() => {
        const fetchData = async () => {
            const messages_res = await getMessages(userId);
            // let reversedMessages = messages_res.messages.slice().reverse();
            setChatMessages(messages_res);

            // setTimeout(() => {
            //     var objDiv = document.getElementById("messages");
            //     if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
            // }, 500);
        };

        fetchData();
        // fetchMessages(currentChatId);
    }, []);

    useEffect(() => {
        socket.on('newmessage', handleSocket);
        return () => {
            socket.removeAllListeners('newmessage');
        };
    }, []);
    useEffect(() => {
        // Подписываемся на событие нового сообщения
        socket.on('newMessage', handleSocket);

        return () => {
            // Отписываемся от события при размонтировании компонента
            socket.removeAllListeners('newMessage');
        };
    }, []);

    useEffect(() => {
        socket.on('serveron', () => setServerOn(true));
        return () => {
            socket.removeAllListeners('serveron');
        };
    }, []);

    const handleKeyPress = (event) => {

        if ((event.code === "Enter" || event.code === 'NumpadEnter') && event.ctrlKey) {
            let inp = document.getElementById('inputmessage');
            let cur_pos = 0;
            if (inp) cur_pos = inp.selectionStart || 0;
            if (inp) setMessage(inp.innerHTML.slice(0, cur_pos) + '\n' + inp.innerHTML.slice(cur_pos));
            if (inp) inp.style.height = inp?.scrollHeight + 'px';
        }
        else if (event.code === "Enter" || event.code === 'NumpadEnter') {
            event.preventDefault();
            let btnsend = document.getElementById("btnsend");
            if (btnsend) btnsend.click();
        }

    };

    useEffect(() => {
        var elem = document.getElementById('inputmessage');

        if (elem) elem.addEventListener("keypress", handleKeyPress);

        return () => {
            if (elem) elem.removeEventListener('keypress', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        setHandleScroll(1, tick);
    }, []);

    const isWav = (path) => path ? path.endsWith('.wav') : false
    const isImage = (path) => path ? ['.png', '.jpg', '.jpeg', '.bmp', '.gif', '.tiff'].some(ext => path.endsWith(ext)) : false

    const handleDownload = (path) => {
        window.open(`${url}/${path}`, '_blank');
    };

    const handleClickUser = async (user) => {
        console.log('handleClickUser', user)
        const messages_res = await getMessages(user.user_id);
        // let reversedMessages = messages_res.messages.slice().reverse();
        setChatMessages(messages_res);
        dispatch(setName(user.company_name))
        dispatch(setUserId(user.user_id))
    }

    return (
        <div>
            <Menu handleClickUser={handleClickUser} />
            {/* <h2>Чат</h2> */}


            <div className="cntr_messages">
                <div onClick={scrollToBottom} className='arrow_down_scroll'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 1024 1024" version="1.1">
                        <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#000000" />
                    </svg>
                </div>

                <div id='messages' className='messages'>
                    {!showLoad &&
                        <div>
                            {/* {chatMessages.slice(startIndexMsg, endIndexMsg + 1).map((message, index) => ( */}
                            {chatMessages.map((message, index) => (
                                <div
                                    onDoubleClick={() => handleDoubleClick(index)}
                                    id={'mess' + message.id}
                                    key={index}
                                    className='row_message'
                                    style={message.from_id === userId ? { justifyContent: 'right' } : { justifyContent: 'left' }}
                                >
                                    <div className='cntr_message_item'>
                                        {/* {message.qm_id &&
                                            <div className='cntr_quoted_message' onClick={() => handleClickQuotedMessage(message.qm_id)}>
                                                <label className='user_name'>{message.qm_user_name}</label>
                                                <div className='message_text'>{message.qm_text}</div>
                                            </div>
                                        } */}
                                        {/* <label className='user_name'>{message.user_name}</label> */}
                                        <div>
                                            {/* {message.path ?
                                                (message.path.slice(message.path.lastIndexOf('.')) === '.jpeg' || message.path.slice(message.text.lastIndexOf('.')) === '.jpg' || message.text.slice(message.text.lastIndexOf('.')) === '.png' ?
                                                    <img style={{ width: '90%' }} src={(message.user_id === 2 || message.user_id === 12 ? `${url}/static/uploads/` : 'http://5.63.153.239:443/static/uploads/') + message.text} alt='' /> :
                                                    message.text.slice(message.text.lastIndexOf('.')) === '.mp4' ?
                                                        <video style={{ width: '90%' }} controls={true}>
                                                            <source src={(message.user_id === 2 || message.user_id === 12 ? `${url}/static/uploads/` : 'http://5.63.153.239:443/static/uploads/') + message.text} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                                                        </video> :
                                                        <a rel='noreferrer' download href={(message.user_id === 2 || message.user_id === 12 ? `${url}/static/uploads/` : 'http://5.63.153.239:443/static/uploads/') + message.text} target='_blank'>{message.text}</a>
                                                ) :
                                                <div className='message_text'>{message.text}</div>
                                            } */}
                                            {
                                                isImage(message.path) &&
                                                <img style={{ width: '90%' }} src={`${url}/${message.path}`} alt='' />
                                            }
                                            {
                                                isWav(message.path) &&
                                                <audio style={{ width: '100%' }} src={`${url}/${message.path}`} controls />
                                            }
                                            {
                                                message.path &&
                                                <div onClick={() => handleDownload(message.path)}>Скачать</div>
                                            }
                                            <div className='message_text'>{message.text}</div>
                                        </div>
                                        <div className='datetime'>{message.datetime.slice(0, 10)} {message.datetime.slice(11, 19)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    {showLoad &&
                        <div className='load_msg'>Загрузка</div>
                    }
                </div>

                {qoutedMessageIndex !== 0 &&
                    <div className='quoted_msg_input'>
                        <svg onClick={() => setQoutedMessageIndex(0)} className='close_svg' xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z" fill="#080341" />
                        </svg>
                        <div className='cntr_quoted_message'>
                            <label className='user_name'>{chatMessages[qoutedMessageIndex].user_name}</label>
                            <div className='message_text'>{chatMessages[qoutedMessageIndex].text}</div>
                        </div>
                    </div>
                }

                <div className='cntr_input_message'>
                    <div className='file_upload'>
                        <label htmlFor="myfile">
                            <svg className='svg_file' width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H12M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M19 9V12M17 19H21M19 17V21" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </label>
                        <input id='myfile' type='file' name='myfile'
                            onChange={() => {
                                let file = document.getElementById("myfile");
                                if (file) {
                                    if (file.files) {
                                        setUploadFile(file.files[0]);
                                    }
                                }
                            }}
                        />
                    </div>
                    <div style={{ width: '30px', cursor: 'pointer' }}>
                        <VoiceRecorder setUploadFile={setUploadFile} />
                    </div>

                    {uploadFile ?
                        <div style={{ width: '60vw' }}>{uploadFile.name}</div> :
                        <textarea id='inputmessage'
                            className="input_message nores"
                            value={message}
                            placeholder="Сообщение..."
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    }

                    <button className='btn_send' id='btnsend' onClick={handleClickSendMessage}>
                        <svg className='svg_file' width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>

        </div>
    );
}

export default Chat;
