import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setName } from '../login/loginSlice';
import { getServices, getUserData } from '../login/loginApi'; // Предположим, что здесь находится ваш файл с функцией getUserData
import { selectUserId } from '../login/loginSlice';
import { url } from "../../static/env";
import './home.css'
import Menu from '../menu';

const Home = ({ setIsLoggedIn }) => {
    const [user, setUser] = useState(null);
    const [services, setServices] = useState(null);

    const username = useSelector(state => state.username); // Предположим, что здесь находится имя пользователя из Redux хранилища
    const dispatch = useDispatch();
    const user_id = useSelector(selectUserId);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await getUserData(user_id); // Вызов функции getUserData для получения данных о пользователе
                setUser(userData.user[0]); // Предполагается, что данные приходят в виде { user: { ... } }
            } catch (error) {
                console.error('Ошибка при загрузке данных о пользователе:', error);
            }
        };

        if (username !== '') {
            fetchData();
        }
    }, [username]);

    const handleLogOut = () => {
        dispatch(setName(''));
    };

    const renderUserInfo = () => {
        if (user) {
            return (
                <div style={{ padding: 10 }}>
                    <p><span className='title_info'>Название компании: </span>{user.name}</p>
                    <p><span className='title_info'>Email: </span>{user.email}</p>
                    <p><span className='title_info'>Телефон: </span>{user.phone}</p>
                    <p><span className='title_info'>ИНН: </span>{user.inn}</p>
                    <p><span className='title_info'>Руководитель: </span>{user.ceo}</p>
                    <p><span className='title_info'>Налогообложение: </span>{user.tax}</p>
                </div>

            );
        } else {
            return (
                <p style={{ textAlign: 'center', marginTop: 20 }}>Загрузка данных...</p>
            );
        }
    };

    const renderAccountant = () => {
        if (user) {
            return (
                <div style={{ padding: 10, width: '80%' }}>
                    <p><span className='title_info'>Имя:</span> {user.acc_name}</p>
                    <p><span className='title_info'>Телефон:</span> {user.acc_phone}</p>
                </div>
            );
        } else {
            return (
                <p style={{ textAlign: 'center', marginTop: 20 }}>Загрузка данных...</p>
            );
        }
    };

    const handleClickUser = async (user) => {
        console.log('handleClickUser', user)
        try {
            const userData = await getUserData(user.user_id); // Вызов функции getUserData для получения данных о пользователе
            setUser(userData.user[0]); // Предполагается, что данные приходят в виде { user: { ... } }
            dispatch(setName(userData.user[0].name))
        } catch (error) {
            console.error('Ошибка при загрузке данных о пользователе:', error);
        }
    }

    async function handleGetServices() {
        try {
            const servicesData = await getServices();
            console.log('getServices', servicesData)
            setServices(servicesData.services[0]);
        } catch (error) {
            console.error('Ошибка при загрузке данных о услугах:', error);
        }
    }

    useEffect(() => {
        handleGetServices()
    }, []);

    return (
        <div>
            <Menu handleClickUser={handleClickUser} />

            <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center', width: '80%' }}>
                <div>
                    {/* <button onClick={() => dispatch(setName(''))}>
                    <span style={{ color: '#777', fontSize: 10, textAlign: 'right' }}>{username}</span>
                </button> */}

                </div>
                <div className='cntr_info'>
                    <div className='cntr_info_2'>
                        <p style={{ fontSize: 20, marginTop: 20 }}>Информация о компании</p>
                        {renderUserInfo()}
                    </div>
                    <div className='cntr_info_2'>
                        <p style={{ fontSize: 20, marginTop: 20 }}>Бухгалтер</p>
                        {renderAccountant()}
                        <div style={styles.photo_container}>
                            {user && (
                                <img
                                    src={`${url}/${user.acc_path}`}
                                    alt="Фото бухгалтера"
                                    style={{ width: 100, height: 100, borderRadius: 10 }}
                                />
                            )}
                        </div>
                    </div>

                </div>
                <div className='cntr_info'>
                    <div>
                        <h4>Услуги</h4>
                        <div></div>
                        <pre>{services}</pre>

                    </div>
                </div>

            </div>
        </div>
    );
};

const styles = {
    photo_container: {
        width: '100%'
    }
};

export default Home;
